<template>
  <onboardClinic backRoute="SuperAdminClinic" />
</template>

<script>
import onboardClinic from "../../components/clinics/onboardClinic.vue";

export default {
  components: {
    onboardClinic
  },
  data: () => ({})
};
</script>
